/** @jsxImportSource @emotion/react */

import { useState, useMemo } from "react";
import { Download } from "lucide-react";
import { ListItemProps } from "./types";
import useDocumentPreview from "hook/useDocumentPreview";
import { Spinner } from "utils/icons";
import { Button } from "components/editor/components";
import { getFileIcon } from "utils/getFileIcon";
import folderIcon from "Assets/folderIcon.svg";

const ListItem = ({ item, type, addType, allSelectedDocuments, allNestedFiles, onRowSelected }: ListItemProps) => {
  const [isAddingItems, setIsAddingItems] = useState(false);
  const { downloadFile } = useDocumentPreview();

  const isItemAdded = useMemo(() => {
    if (type === "directory") {
      return !!allNestedFiles?.every((file) => allSelectedDocuments?.find(({ file_id }) => file_id === file.id));
    }

    return allSelectedDocuments.find((doc) => doc.file_id === item.id);
  }, [allNestedFiles, allSelectedDocuments, item.id, type]);

  return (
    <div
      className="group w-full pl-4 pr-6 flex gap-4 items-center justify-between hover:bg-action-lightest"
      onClick={() => onRowSelected?.()}
    >
      <div className="group flex flex-1 py-4 cursor-pointer overflow-hidden items-center gap-1">
        <img
          className="h-[24px] w-[24px] object-contain"
          src={"file_extension_type" in item ? getFileIcon(item.file_extension_type) : folderIcon}
          alt="file-icon"
        />

        <p className="text-sm pl-2 text-gray-700 pt-[1px] truncate max-w-full w-max text-left">{item?.name}</p>
        {type === "directory" && (
          <p className="text-[10px] pt-[3px] text-[#5B6B79] whitespace-nowrap">({allNestedFiles?.length} Documents)</p>
        )}

        {type === "document" && (
          <button
            onClick={() => {
              if ("download_url" in item) downloadFile(item.download_url);
            }}
            className="opacity-0 text-[16px] pb-0.5 text-action duration-150 hover:text-action-hover group-hover:opacity-100"
          >
            <Download size={16} />
          </button>
        )}
      </div>
      <Button
        variant="link"
        size="md"
        className="flex items-center gap-2 text-[14px] font-medium rounded opacity-0 pointer-events-none group-hover:opacity-100 group-hover:pointer-events-auto"
        onClick={(event) => {
          event?.stopPropagation();
          setIsAddingItems(true);
          addType(item, () => setIsAddingItems(false));
        }}
        disabled={isItemAdded}
      >
        {isAddingItems ? (
          <Spinner classes="!text-black" />
        ) : (
          <>
            {isItemAdded ? (
              <>Added</>
            ) : (
              <>
                <span className="text-[18px]">+</span> Add
              </>
            )}
          </>
        )}
      </Button>
    </div>
  );
};

export default ListItem;
