import { Mark, Node } from "@tiptap/pm/model";
import { FindMarkResult } from "./types";
import { Editor } from "@tiptap/react";

declare global {
  interface Window {
    commentEditors?: Record<string, Editor>;
  }
}

export const findMarkInRange = (
  doc: Node,
  from: number,
  to: number,
  predicate: (value: Mark, index: number, array: readonly Mark[]) => boolean
): FindMarkResult | undefined => {
  let result: FindMarkResult | undefined = undefined;
  doc.nodesBetween(from, to, (node, pos) => {
    if (node.marks && node.marks.length > 0) {
      for (let index = 0; index < node.marks.length; index++) {
        const mark = node.marks[index];
        if (predicate(mark, index, node.marks)) {
          result = { mark, pos, node };
          return false;
        }
      }
    }
    return !result; // Continue if no mark has been found yet
  });
  return result as FindMarkResult | undefined;
};

export const findMarksInRange = (
  doc: Node,
  from: number,
  to: number,
  predicate: (value: Mark, index: number, array: readonly Mark[]) => boolean
): FindMarkResult[] => {
  let results: FindMarkResult[] = [];
  doc.nodesBetween(from, to, (node, pos) => {
    if (node.marks && node.marks.length > 0) {
      for (let index = 0; index < node.marks.length; index++) {
        const mark = node.marks[index];
        if (predicate(mark, index, node.marks)) {
          results.push({ mark, pos, node });
          return false;
        }
      }
    }
    return true;
  });
  return results;
};

export const getActiveCommentMarkWithRange = (doc: Node, from: number, to: number) => {
  let activeCommentId: string | null = null;
  let newActiveCommentPosition: { from: number; to: number } | null = null;
  // Get active mark in selection
  const activeMarkInRange = findMarkInRange(
    doc,
    from,
    to,
    (mark) => mark.type.name === "comment" && !mark.attrs.resolved
  );
  if (activeMarkInRange) {
    activeCommentId = activeMarkInRange.mark.attrs.id;
    newActiveCommentPosition = {
      from: activeMarkInRange.pos,
      to: activeMarkInRange.pos + activeMarkInRange.node.nodeSize,
    };
    // Find all marks with that active id - there can me multiple incase the mark has been split with another mark
    const markRanges = findMarksInRange(
      doc,
      0,
      doc.nodeSize - 2,
      (mark) => mark.type.name === "comment" && mark.attrs.id === activeCommentId
    );
    if (markRanges.length > 0) {
      newActiveCommentPosition = {
        from: markRanges[0].pos,
        to: markRanges[0].pos + markRanges[0].node.nodeSize,
      };
      markRanges.forEach((markInRange) => {
        const { node, pos } = markInRange;
        const start = pos;
        const end = pos + node.nodeSize;
        const { from, to } = newActiveCommentPosition as unknown as {
          from: number;
          to: number;
        };
        newActiveCommentPosition = { from: Math.min(from, start), to: Math.max(to, end) };
      });
    }

    return (
      ({ activeCommentId, activeCommentPosition: newActiveCommentPosition } as {
        activeCommentId: string;
        activeCommentPosition: { from: number; to: number };
      }) || {
        activeCommentId: null,
        activeCommentPosition: null,
      }
    );
  }
};

export const extractThreadIds = (doc: object) => {
  const commentIds: string[] = [];

  function traverse(node: Node | Node[]): void {
    if (Array.isArray(node)) {
      node.forEach(traverse);
    } else if (typeof node === "object") {
      if (node.marks) {
        node.marks.forEach((mark) => {
          // @ts-ignore
          if (mark.type === "comment" && mark.attrs?.id) {
            commentIds.push(mark.attrs.id);
          }
        });
      }
      if (node.content) {
        // @ts-ignore
        traverse(node.content);
      }
    }
  }
  // @ts-ignore
  traverse(doc.content);
  return commentIds;
};
