import { Extraction, SourceType } from "./CopilotSchemaTypes";
import { LiveList, LiveObject, ToImmutable } from "YJSProvider/LiveObjects";

export enum SectionStatus {
  Todo = 1,
  InProgress = 2,
  InReview = 3,
  Done = 4,
}

export enum RequirementStatus {
  Todo = "Not Started",
  InProgress = "In Progress",
  InReview = "Needs Review",
  Done = "Completed",
}

export enum RequirementCompliance {
  Empty = "None",
  NotCompliant = "Not Compliant",
  Partial = "Partial",
  Full = "Full",
}

// Compliance Matrix
export type Requirement = {
  id: string;
  content: string;
  summarized_content?: string; // (legacy)
  section: string; // RFP Subsection
  header: string; // RFP Section
  disregarded?: boolean;
  section_order?: number;
  page_number?: string;
  extraction_number?: string;
  extraction_id?: string;
  matched_keywords?: string[];
  skipped?: boolean;
  generated_heading?: string;
  element_id?: string;
  soft_deleted?: boolean;
};

export type ProposalReference = {
  volume_id?: string; // Maps to the volume id in framework (Proposal Volume)
  section_id?: string; // Maps to section id (Proposal Section)
  subsection_id?: string; // Maps to section id of section with parent_id (Proposal Section)
  paragraph?: number;
};

export type Document = {
  id: string;
  name: string;
};

export type File = {
  id: string;
  name: string;
  content?: string;
  file_extension_type?: string;
  created_at?: string;
};

export type ComplianceMatrixRowContentBody = {
  content: string;
  reference_id?: string | null;
  source_name: string;
  source_type: SourceType;
  source_extension_type?: string;
  source_content: string;
};

export type WritingPrompt = {
  id: string;
  content: string;
  file_id_filters: string[];
};

export type UserInstruction = {
  id: string;
  content: string;
};

export type ComplianceMatrixRow = {
  requirement: Requirement;
  proposal_reference: ProposalReference;
  document?: Document;
  compliance_status?: RequirementCompliance | "" | null;
  risk_status?: "Low" | "Medium" | "High" | "";
  requirement_status?: RequirementStatus | "" | null;
  notes: string;
  written_content?: string;
  written_html_content?: string;
  thread_ids?: string[];
  generated_content_ideas_v2?: ComplianceMatrixRowContentBody[];
  selected_content_v2?: ComplianceMatrixRowContentBody[];
  assigned_user_ids?: string[];
  response_generated?: boolean;
  is_response_generating?: boolean;
  is_response_in_queue?: boolean;
  auto_response_actor?: string;
  requirement_file_id_filters?: string[];
  writing_prompts?: WritingPrompt[];
  user_instructions?: UserInstruction[];
  response_sources?: ResponseSource[];
  locked?: boolean;
  locked_actor?: string;
};

// Framework
export type Section = {
  id: string;
  title: string;
  parent_id?: string;
  annotate_proposal?: boolean;
  theme?: string;
  theme_active?: boolean;
  proposal?: string;
  proposal_generated?: boolean;
  locked?: boolean;
  proposal_sources?: (BaseSource | ResponseSource)[];
  assignees?: string[];
  status?: SectionStatus;
  section_manager?: {
    isIgnored: boolean;
  };
};

export type Volume = {
  id: string;
  title: string;
  sections: Section[];
  updated_at?: string;
  assignees?: string[];
};

export type Template = {
  id?: string; // (legacy or imported if empty)
  isDirty?: boolean;
};

export type Framework = {
  volumes: Volume[];
  template?: Template;
};

export type WinTheme = {
  id: string;
  content: string;
};

// legacy
export type BaseSource = {
  name: string;
  extension_type: string;
  content: string;
  reference_id: string;
  date: string;
  citation_reference?: string;
};

export type UsedFileContents = {
  id: string;
  partition_order_key: number;
  content: string;
  requirement_source_citations: string;
};

export type ResponseSource = {
  file_id: string;
  name: string;
  extension_type: string;
  date: string;
  fetched_date: string;
  used_file_contents: UsedFileContents[];
};

export type BlockSource = {
  name: string;
  extension_type: string;
  content: string;
  reference_id: string;
  date: string;
  citation_reference?: string;
};

export type BaseBlock = {
  id: string;
  updated_at: string;
  body: string;
  sources: BlockSource[];
};

export type CoreBlock = { type: AssistantBlockType.CoreBlock } & BaseBlock;

export type VultronBlock = {
  type: AssistantBlockType.VultronBlock;
  prompt?: string;
  promptSources?: string[];
  error?: boolean;
  enableInternet?: boolean;
} & BaseBlock;

export enum AssistantBlockType {
  CoreBlock = 1,
  VultronBlock,
}

export type WritingAssistantBlock = CoreBlock | VultronBlock;

export type WritingAssistant = {
  user_id: string;
  conversation: WritingAssistantBlock[];
};

export type Sheet = {
  id: string; // extraction_id/extraction_number
  name: string;
};

export type Storage = {
  extractions?: ToImmutable<LiveList<LiveObject<Extraction>>>;
  compliance_matrix: ComplianceMatrixRow[];
  sheets?: Sheet[];
  framework: Framework;
  win_themes?: WinTheme[];
  writing_assistant?: { [key: WritingAssistant["user_id"]]: WritingAssistant };
};
