import axios from "axios";

export const authenticateToProposal = async (requestType: string, internalContractId: string, requestId: string) => {
    const response = await axios
        .post(`/contracts/internal/${internalContractId}/proposal/${requestType}/request_edit`, {
            id: requestId,
            provider: "yjs",
        })
        .then((response) => {
            if (response.status === 401) {
                // @ts-ignore
                window.location = "/auth";
                return Promise.reject();
            }
            return response.data;
        })
        .catch((e) => {
            if (e.response.status === 401) {
                // @ts-ignore
                window.location = "/auth";
            }
        });
    const token = response.token;
    if (!token) throw new Error("No token in response");
    return token;
};
