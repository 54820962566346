import Placeholder from "@tiptap/extension-placeholder";
import Paragraph from "@tiptap/extension-paragraph";
import Document from "@tiptap/extension-document";
import Text from "@tiptap/extension-text";

export const getCommentEditorExtensions = (placeholder: string) => [
  Document,
  Text,
  Paragraph,
  Placeholder.configure({ placeholder, emptyEditorClass: "tiptap-empty" }),
];
