import { DragEndEvent, DragStartEvent, KeyboardSensor, PointerSensor, useSensor, useSensors } from "@dnd-kit/core";
import { sortableKeyboardCoordinates } from "@dnd-kit/sortable";
import { Storage } from "components/copilot/CopilotSchemaTypes";
import usePageVisibility from "hook/usePageVisibility";
import { useCallback, useEffect, useRef } from "react";
import { setRequirementsState } from "store/reducers/draft/sectionReducer";
import { getProject } from "store/reducers/projectReducer";
import { useAppDispatch, useAppSelector } from "store/storeTypes";
import { move } from "utils/array";
import { useTrackUserMetric } from "utils/metrics";
import { useMutation } from "YJSProvider/createYJSContext";
import { filter, find, update } from "YJSProvider/LiveObjects";

export const useDrag = () => {
  const dispatch = useAppDispatch();

  const sensors = useSensors(
    useSensor(PointerSensor),
    useSensor(KeyboardSensor, {
      coordinateGetter: sortableKeyboardCoordinates,
    })
  );

  const handleDragStart = useCallback(
    (event: DragStartEvent) => {
      dispatch(setRequirementsState({ activeDragRequirementId: event.active.id }));
    },
    [dispatch]
  );

  const handleDragCancel = useCallback(() => {
    dispatch(setRequirementsState({ activeDragRequirementId: null }));
  }, [dispatch]);

  const handleDragEnd = useMutation(({ storage }, event: DragEndEvent) => {
    const { active, over } = event;

    if (over?.id && active.id !== over.id) {
      const complianceMatrixRows = storage.get("compliance_matrix") as Storage["compliance_matrix"] | undefined;
      if (!complianceMatrixRows) return;
      const liveDraggableRow = find(complianceMatrixRows, (row) => row.get("requirement")?.get("id") === active.id);
      const sectionId = liveDraggableRow?.get("proposal_reference")?.get("section_id");
      if (!liveDraggableRow || !sectionId) return;

      const rowsInSection = filter(
        complianceMatrixRows,
        (row) => row?.get("proposal_reference").get("section_id") === sectionId
      );
      const doesNotHaveFullOrdering = rowsInSection.some(
        (row) => typeof row.get("requirement")?.get("section_order") !== "number"
      );
      if (doesNotHaveFullOrdering) {
        rowsInSection.forEach((row, idx) => {
          const requirement = row?.get("requirement");
          requirement?.set("section_order", idx);
        });
      }
      rowsInSection.sort(
        (a, b) => (a.get("requirement")?.get("section_order") || 0) - (b.get("requirement")?.get("section_order") || 0)
      );
      const destinationIndex = rowsInSection.findIndex((row) => row.get("requirement")?.get("id") === over.id);
      const sourceIndex = rowsInSection.findIndex((row) => row.get("requirement")?.get("id") === active.id);

      const sortedRows = move([...rowsInSection], sourceIndex, destinationIndex);
      sortedRows.forEach((row, idx) => {
        row.get("requirement")?.set("section_order", idx);
      });
    }

    dispatch(setRequirementsState({ activeDragRequirementId: null }));
  }, []);

  return { sensors, handleDragStart, handleDragEnd, handleDragCancel };
};

export const useCatchResizeObserver = () => {
  useEffect(() => {
    if (process.env["NODE_ENV"] !== "development") return;

    const catchErr = (e: ErrorEvent) => {
      if (e.message === "ResizeObserver loop completed with undelivered notifications.") {
        const resizeObserverErrDiv = document.getElementById("webpack-dev-server-client-overlay-div");
        const resizeObserverErr = document.getElementById("webpack-dev-server-client-overlay");

        if (resizeObserverErr) {
          resizeObserverErr.setAttribute("style", "display: none");
        }
        if (resizeObserverErrDiv) {
          resizeObserverErrDiv.setAttribute("style", "display: none");
        }
      }
    };
    window.addEventListener("error", catchErr);

    return () => {
      window.removeEventListener("error", catchErr);
    };
  }, []);
};

export const usePollActiveProject = (internalContractId?: string) => {
  const isPageVisible = usePageVisibility();
  const timerIdRef = useRef<NodeJS.Timeout>();
  const dispatch = useAppDispatch();
  const trackUserEvent = useTrackUserMetric();

  useEffect(() => {
    const pollingCallback = async () => {
      if (internalContractId) dispatch(getProject({ internalContractId }));
    };

    const startPolling = () => {
      pollingCallback();
      timerIdRef.current = setInterval(pollingCallback, 20000);
    };

    const stopPolling = () => {
      clearInterval(timerIdRef.current);
    };

    if (isPageVisible) {
      startPolling();
      trackUserEvent("Projects: Project Opened");
    } else {
      stopPolling();
    }

    return () => {
      stopPolling();
    };
  }, [dispatch, isPageVisible, internalContractId]);
};

export const useCloseInifiniteResponseGenerations = () => {
  const currentUserId = useAppSelector((store) => store.auth.currentUser?.id);

  const validateAndClearInifiniteResponsesGenerating = useMutation(
    ({ storage }) => {
      const matrix = storage.get("compliance_matrix") as Storage["compliance_matrix"];
      const infiniteResponsesGenerating = filter(
        matrix,
        (row) =>
          row.get("auto_response_actor") === currentUserId &&
          (!!row.get("is_response_in_queue") || !!row.get("is_response_generating"))
      );

      if (!infiniteResponsesGenerating.length) return;

      infiniteResponsesGenerating.forEach((row) => {
        update(row, { is_response_in_queue: false, is_response_generating: false, auto_response_actor: "" });
      });
    },
    [currentUserId]
  );

  useEffect(() => {
    validateAndClearInifiniteResponsesGenerating();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
};
