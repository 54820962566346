import { useQuery, UseQueryOptions } from "@tanstack/react-query";
import axios from "axios";
import { CaptureSchema } from "types/Capture";

export const SCHEMAS_QUERY_KEY = "capture/schemas";

const useGetSchemas = (options?: UseQueryOptions<CaptureSchema[], Error>) => {
  const { data, ...rest } = useQuery<CaptureSchema[], Error>({
    queryKey: [SCHEMAS_QUERY_KEY],
    queryFn: () =>
      axios
        .get("/forms/schema")
        .then((resp) => resp.data)
        .catch(() => {}),
    ...options,
  });

  return {
    data,
    ...rest,
  };
};

export default useGetSchemas;
