import { useNotification } from "context/notificationContext";
import { useCallback } from "react";
import { resetAuth } from "store/reducers/authReducerSlice";
import { useAppDispatch } from "store/storeTypes";
import axios from "axios";

const useLogout = () => {
    const dispatch = useAppDispatch();
    const { setToast } = useNotification();

    const sso = localStorage.getItem("vultron_user_sso") === "true";

    const cleanUp = () => {
        localStorage.removeItem("vultron_workspace_id");
        localStorage.removeItem("vultron_user_token");
        localStorage.removeItem("vultron_user_sso");
        // @ts-ignore
        window.Intercom("shutdown");
        dispatch(resetAuth());
    };

    return useCallback(() => {
        if (sso) {
            cleanUp();
            window.location.href = "/auth";
            return;
        }

        axios
            // use credentials to send cookies for revoking refresh and deleting the cookie
            .post("/users/auth0/logout", {}, { withCredentials: true })
            .then((response) => {
                cleanUp();
                window.location.href = response.data?.logout_url;
            })
            .catch((error) => {
                setToast.error({
                    msg: "Failed to logout. Please try again and if the issue persists please contact support@vultron.ai.",
                });
            });
    }, [dispatch, setToast]);
};

export default useLogout;
