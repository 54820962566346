import BasicSidebarItem from "components/atoms/basic-sidebar-item";
import { Skeleton } from "components/molecules/skeleton";
import useGetSchemas from "hook/capture/useGetSchemas";
import { useFlags } from "launchdarkly-react-client-sdk";
import { useEffect } from "react";
import { Outlet, useNavigate, useSearchParams } from "react-router-dom";
import { FeatureFlagSet } from "types/FeatureFlagSet";
import { CopilotPresencePage } from "types/Presence";

const Capture = () => {
  const [searchParams] = useSearchParams();
  const { data: schemas, isLoading } = useGetSchemas();
  const flags = useFlags<FeatureFlagSet>();
  const navigate = useNavigate();

  useEffect(() => {
    if (!flags.capture) {
      navigate({
        pathname: "/dashboard/contracts/details",
        search: new URLSearchParams({
          ...Object.fromEntries(searchParams.entries()),
          tab: CopilotPresencePage.Project,
        }).toString(),
      });
    }
  }, [flags.capture, navigate, searchParams]);
  console.log(schemas, isLoading);

  return (
    <div className="flex h-full">
      <div className="p-6 flex-col flex gap-y-1 border-r border-gray-300 text-[#757575]">
        {isLoading && new Array(4).fill(0).map(() => <Skeleton borderRadius={4} height={44} width={160} />)}
        {!isLoading &&
          !!schemas?.length &&
          schemas.map((schema) => (
            <BasicSidebarItem to={{ pathname: schema.title, search: searchParams.toString() }}>
              {schema.title}
            </BasicSidebarItem>
          ))}

        <BasicSidebarItem to={{ pathname: "win-themes", search: searchParams.toString() }}>Win Themes</BasicSidebarItem>
      </div>
      <div className="flex-1 p-6 overflow-y-auto">
        <Outlet />
      </div>
    </div>
  );
};

export default Capture;
