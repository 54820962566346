import { Skeleton } from "components/molecules/skeleton";
import { useParams } from "react-router-dom";

const CaptureForm = () => {
  const { schemaType } = useParams();
  const isLoading = !schemaType || true;

  return (
    <div className="flex flex-col gap-4">
      {isLoading && new Array(4).fill(0).map(() => <Skeleton borderRadius={4} height={181} width="100%" />)}
    </div>
  );
};

export default CaptureForm;
