import { Mark, Node } from "@tiptap/pm/model";
import { YJSProvider } from "YJSProvider/YJSProvider";

export interface Comment {
  id: string;
  content: string;
  commenter: {
    id: string;
    username: string;
    email: string;
    is_vultron: boolean;
  };
  created_at: Date;
  updated_at: Date;
}

export interface CommentThread {
  commenter_id: string;
  comments: Comment[];
  created_at: Date;
  deleted: boolean;
  id: string;
  internal_contract_id: string;
  is_read: boolean;
  quote_text: string;
  reference_id: string;
  resolved: boolean;
  updated_at: Date;
}

export interface FindMarkResult {
  mark: Mark;
  pos: number;
  node: Node;
}

export interface CommentsExtensionOptions {
  provider: YJSProvider;
}

export enum ThreadContext {
  PROPOSAL = "PROPOSAL_DOC",
  DRAFT = "DRAFT",
}

export enum CommentsFilterState {
  Resolved = "Resolved",
  Open = "Open",
}
