import { uniqBy } from "lodash";
import { useCallback, useEffect, useRef, useState } from "react";
import { GroupedBlock, MergedRequirement } from "../types";
import { Extraction } from "components/copilot/CopilotSchemaTypes";
import { SelectionEvent } from "@viselect/vanilla";
import { LiveObject, ToImmutable } from "YJSProvider/LiveObjects";
import axios from "axios";
import { useParams, useSearchParams } from "react-router-dom";
import { useNotification } from "context/notificationContext";
import { useAppDispatch, useAppSelector } from "store/storeTypes";
import {
  getFilteredRequirements,
  getRequirementGroups,
  getTemplateRequirements,
} from "store/reducers/extract/CurrentExtractionReducer";
import useExtractionOperations from "hook/useExtractionOperations";
import { createComplianceMatrixRowRequirement } from "utils/complianceMatrix";

export const useDragSelectOperation = (allFilteredBlocks: GroupedBlock[], extraction?: ToImmutable<Extraction>) => {
  const [selectedBlocks, setSelectedBlocks] = useState<GroupedBlock[]>([]);
  const selectionRef = useRef<SelectionEvent["selection"]>();

  const clearSelection = useCallback(() => {
    setSelectedBlocks([]);
    selectionRef.current?.clearSelection();
  }, []);

  useEffect(() => {
    clearSelection();
  }, [clearSelection, extraction?.step]);

  const onMove = useCallback(
    ({
      store: {
        changed: { added, removed },
      },
    }: SelectionEvent) => {
      added.forEach((node) => {
        if (node instanceof HTMLElement) {
          document
            .querySelectorAll(`[data-element='${node.dataset.element}']`)
            .forEach((node) => node.classList.add("highlighted-dragged-selected-requirement"));
        }
      });
      removed.forEach((node) => {
        if (node instanceof HTMLElement) {
          document
            .querySelectorAll(`[data-element='${node.dataset.element}']`)
            .forEach((node) => node.classList.remove("highlighted-dragged-selected-requirement"));
        }
      });
    },
    []
  );

  const onStop = useCallback(
    ({ event, selection, store: { stored } }: SelectionEvent) => {
      if (!event) return;
      document.body.style.userSelect = "unset";
      selectionRef.current = selection;
      const dragSelectedBlocks = uniqBy(allFilteredBlocks, "requirement.requirement.element_id").filter((block) =>
        Array.from(stored).some((item) => {
          if (item instanceof HTMLElement) {
            return item.dataset.element && item.dataset.element === block.id;
          }
          return false;
        })
      );
      setSelectedBlocks(dragSelectedBlocks);
    },
    [allFilteredBlocks]
  );

  const onBeforeStart = useCallback(
    ({ event, store }: SelectionEvent) => {
      document.body.style.userSelect = "none";
      const target = event?.target as HTMLElement;

      clearSelection();
      if (!!store.stored.length) return false;
      return !target?.closest(".ds-selectable");
    },
    [clearSelection]
  );

  return { selectedBlocks, clearSelection, onMove, onStop, onBeforeStart };
};

type MergeRequirementsVariables = {
  requirement_ids: string[];
};

export const useMergeRequirements = () => {
  const { extractionId } = useParams();
  const [isLoading, setIsLoading] = useState(false);
  const [searchParams] = useSearchParams();
  const projectId = searchParams.get("id");
  const { setToast } = useNotification();
  const dispatch = useAppDispatch();
  const { setBulkExtractionRequirementsMerged, createAndInsertRequirement } = useExtractionOperations();
  const extractionComplianceMatrix = useAppSelector(
    (store) => store.currentExtractionState.currentExtraction?.compliance_matrix
  );

  const mergeRequirements = useCallback(
    async (body: MergeRequirementsVariables) => {
      if (isLoading || !extractionId) return;
      setIsLoading(true);

      try {
        const { data } = await axios.post<MergedRequirement>(`/autopilot/${projectId}/requirements/groups`, body);

        const childRequirements =
          extractionComplianceMatrix?.filter(
            (row) =>
              row.requirement.element_id &&
              data.child_requirements.some((childRequirement) => childRequirement.id === row.requirement.id)
          ) || [];

        const firstYjsRequirementIdx =
          extractionComplianceMatrix?.findIndex(
            (row) => childRequirements[0]?.requirement?.id === row.requirement.id
          ) || 0;
        const firstRequirementWithAssignment = childRequirements.find((row) => !!row.proposal_reference.section_id);
        const createdRequirement = createComplianceMatrixRowRequirement({
          content: data.content,
          id: data.id,
          skipped: childRequirements[0].requirement?.skipped,
          element_id: childRequirements[0]?.requirement?.element_id,
        });
        const rowProperties = {
          document: new LiveObject({ id: data.document_id }),
          requirement: createdRequirement,
          written_content: data.response || "",
          proposal_reference: new LiveObject({
            volume_id: firstRequirementWithAssignment?.proposal_reference?.volume_id || "",
            section_id: firstRequirementWithAssignment?.proposal_reference?.section_id || "",
          }),
        };
        if (projectId) {
          await dispatch(getRequirementGroups({ projectId }));
          await dispatch(getFilteredRequirements({ projectId }));
        }
        createAndInsertRequirement(extractionId, firstYjsRequirementIdx, rowProperties);

        if (extractionId) {
          setBulkExtractionRequirementsMerged(extractionId, body.requirement_ids);
          await dispatch(getTemplateRequirements(extractionId));
        }
      } catch (e) {
        if (axios.isAxiosError(e) && e.response) {
          setToast.error({ msg: e.response.data.error_msg });
        }
      } finally {
        setIsLoading(false);
      }
    },
    [
      createAndInsertRequirement,
      dispatch,
      extractionComplianceMatrix,
      extractionId,
      isLoading,
      projectId,
      setBulkExtractionRequirementsMerged,
      setToast,
    ]
  );

  return { mergeRequirements, isLoading };
};

export const useUnmergeRequirement = () => {
  const { extractionId } = useParams();
  const [isLoading, setIsLoading] = useState(false);
  const [searchParams] = useSearchParams();
  const projectId = searchParams.get("id");
  const { setToast } = useNotification();
  const dispatch = useAppDispatch();
  const { unmergeRequirement } = useExtractionOperations();
  const mergedRequirements = useAppSelector((store) => store.currentExtractionState.mergedRequirements);

  const handleUnmergeRequirement = useCallback(
    async (requirementId: string) => {
      if (isLoading || !extractionId) return;
      setIsLoading(true);

      try {
        await axios.delete<MergedRequirement>(`/autopilot/${projectId}/requirements/groups/${requirementId}`);

        const mergedRequirement = mergedRequirements.find(
          (mergedRequirement) => mergedRequirement.id === requirementId
        );

        if (extractionId && mergedRequirement)
          unmergeRequirement(
            extractionId,
            mergedRequirement.id,
            mergedRequirement.child_requirements.map(({ id }) => id)
          );
        if (projectId) {
          await dispatch(getRequirementGroups({ projectId }));
          await dispatch(getFilteredRequirements({ projectId }));
        }

        if (extractionId) await dispatch(getTemplateRequirements(extractionId));
      } catch (e) {
        if (axios.isAxiosError(e) && e.response) {
          setToast.error({ msg: e.response.data.error_msg });
        }
      } finally {
        setIsLoading(false);
      }
    },
    [dispatch, extractionId, isLoading, mergedRequirements, projectId, setToast, unmergeRequirement]
  );

  return { handleUnmergeRequirement, isLoading };
};
