import { useRoom } from "YJSProvider/createYJSContext";
import { Loading } from "components/yjs-editor/components/Loading";
import { EditorView } from "prosemirror-view";
import { useSelector } from "react-redux";
import { TextEditor } from "components/yjs-editor/components/TextEditor";
import { useRef } from "react";
import { Toolbar } from "components/yjs-editor/components/Toolbar";
import { Suspense } from "react";

export const EditorToggler = ({ liveCursor, fullscreen }: { liveCursor: boolean; fullscreen?: boolean }) => {
  const room = useRoom();
  const { workspaceMembers, currentUser } = useSelector((store: any) => store.auth);
  const liveUsersInADoc = workspaceMembers.filter((obj1: { id: string }) => obj1.id === currentUser.id);

  return (
    <Suspense fallback={<Loading />}>
      <EditorWithToolbar
        fullscreen={fullscreen}
        liveCursor={liveCursor}
        color={liveUsersInADoc[0].color}
        roomId={room.id}
      />
    </Suspense>
  );
};

const EditorWithToolbar = ({
  fullscreen,
  color,
  liveCursor,
  roomId,
}: {
  color: string;
  fullscreen?: boolean;
  liveCursor: boolean;
  roomId: string;
}) => {
  const toolbarContainerRef = useRef<HTMLDivElement>(null);
  const storeEditorOfYJSTipTap = useSelector((state: any) => state.yjsEditor.storeEditorOfYJSTipTap);
  return (
    <>
      <Suspense fallback={<Loading />}>
        <div className="flex py-2" ref={toolbarContainerRef}>
          {storeEditorOfYJSTipTap && (
            <Toolbar
              fullscreen={fullscreen}
              editor={storeEditorOfYJSTipTap}
              toolbarContainerRef={toolbarContainerRef}
            />
          )}
        </div>
        <TextEditor fullscreen={fullscreen} color={color} liveCursor={liveCursor} roomId={roomId} />
      </Suspense>
    </>
  );
};

// Prevents a matchesNode error on hot reloading
EditorView.prototype.updateState = function updateState(state: { plugins: any }) {
  // @ts-ignore
  if (!this.docView) return;
  // @ts-ignore
  // eslint-disable-next-line eqeqeq
  this.updateStateInner(state, this.state.plugins != state.plugins);
};
