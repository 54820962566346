import { EditorContent, useEditor } from "@tiptap/react";
import { EditorView } from "prosemirror-view";
import { Suspense, useCallback, useEffect, useMemo, useRef, useState } from "react";
import * as Y from "yjs";
import styles from "./TextEditor.module.css";
import classNames from "classnames";
import { PROSE_CONTAINER_ID } from "components/editor/constants";
import { CopilotPresencePage } from "types/Presence";
import { Loading } from "./Loading";
import { SelectionMenu } from "./SelectionMenu";
import { selectedTextAction } from "store/reducers/yjs-editor-reducer";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import AskAiSearchBar, { DropDownMenu, ResponseDropDown, ToneDropDown } from "./AskAiToolbar/SearchBarInput";
import {
  editorPrevSelectionAction,
  prevDomSelectionAction,
  resetEditorStateAction,
  storeEditorStateAction,
} from "store/reducers/yjs-editor-reducer";

import LiveNameCursors from "components/molecules/live-name-cursors";
import { useTrackUserMetric } from "utils/metrics";
import { YJSProvider } from "YJSProvider/YJSProvider";
import { useRoom } from "utils/yjs-configs/proposal-document/yjs.config";
import { CommentDraft } from "components/Comments/components/CommentDraft";
import { useSearchParams } from "react-router-dom";
import { useActiveThread } from "components/Comments/components/useThread";
import { useAppSelector } from "store/storeTypes";
import { toggleComments } from "store/reducers/copilot/copilotDrawerReducer";
import { setActiveCommentEditor } from "store/reducers/copilot/copilotReducer";
import "../style/global.css";
import { getExtensions } from "./extensions";
import { ThreadContext } from "components/Comments/types";
import { useCommentOperations } from "api/comments/useCommentOperations";
import { setHighlightedText } from "store/reducers/writing-assistant/writingAssistantReducer";

export function TextEditor({
  color,
  liveCursor,
  roomId,
  fullscreen,
}: {
  color: string;
  liveCursor: boolean;
  roomId: string;
  fullscreen?: boolean;
}) {
  return (
    <Suspense fallback={<Loading />}>
      <Editor color={color} liveCursor={liveCursor} roomId={roomId} fullscreen={fullscreen} />
    </Suspense>
  );
}

export function Editor({
  color,
  liveCursor,
  roomId,
  fullscreen,
}: {
  color: string;
  liveCursor: boolean;
  roomId: string;
  fullscreen?: boolean;
}) {
  const room = useRoom();
  const currentUserData = useSelector((store: any) => store.auth);

  if (!room.awarenessProvider) return <Loading />;

  return (
    <TiptapEditor
      fullscreen={fullscreen}
      liveCursor={liveCursor}
      roomId={roomId}
      name={currentUserData.currentUser.username}
      color={color}
      doc={room.awarenessDoc}
      provider={room.awarenessProvider}
    />
  );
}

type EditorProps = {
  doc: Y.Doc;
  name: string;
  color: string;
  provider: YJSProvider;
  liveCursor: boolean;
  roomId: string;
  fullscreen?: boolean;
};

function TiptapEditor({ doc, name, color, provider, liveCursor, roomId, fullscreen }: EditorProps) {
  const dispatch = useDispatch();
  const trackUserMetric = useTrackUserMetric();
  const roomIdParts = useMemo(() => roomId.split("/"), [roomId]);
  const [searchParams] = useSearchParams();
  const internalContractId = searchParams.get("id")?.toLocaleLowerCase();
  const referenceId = searchParams.get("docId")?.toLocaleLowerCase();

  const { toggleAskAiInputWthDrpDown, selectedText } = useSelector((state: any) => state.yjsEditor);

  const editorResponse = useSelector((state: any) => state.aiReducer.editorResponse);
  const scrollDiv = document.getElementById("scrollDiv");
  const proseElement = document.getElementById("tiptapEditor");
  const { deleteThreadMutation, restoreThreadMutation } = useCommentOperations(
    internalContractId!,
    referenceId as string
  );

  const removeThread = useCallback(
    (thread: string) => deleteThreadMutation.mutate({ thread_id: thread }),
    [deleteThreadMutation]
  );
  const restoreThread = useCallback(
    (thread: string) => restoreThreadMutation.mutate({ thread_id: thread }),
    [restoreThreadMutation]
  );

  const extensions = useMemo(
    () =>
      getExtensions({
        provider,
        doc,
        user: {
          name,
          color,
          picture: "",
        },
        removeThread,
        restoreThread,
      }),
    [provider, doc, name, color, removeThread, restoreThread]
  );

  const editor = useEditor({
    editorProps: {
      attributes: {
        class: styles.editor,
        id: "proposal-tiptap-editor",
      },
      transformPastedHTML(html) {
        return html.replace(/<img.*?>/g, "");
      },
    },
    extensions,
    onSelectionUpdate: ({ editor }) => {
      if (!editor.isEditable) return;
      const selection = editor.state.selection;
      const { from, to } = selection;
      const text = !selection.empty ? editor.state.doc.textBetween(from, to, "\n") : "";
      if (!text) {
        return;
      }
      dispatch(setHighlightedText(text));
    },
  });
  const editorRef = useRef<HTMLDivElement>(null);
  useEffect(() => {
    if (editor) {
      const currentSelection = editor.state.selection;
      dispatch(editorPrevSelectionAction(currentSelection));
      dispatch(storeEditorStateAction(editor));
      if (roomIdParts[0] === "document") {
        trackUserMetric("Proposals: Proposal Edited", {
          proposal_document_id: roomIdParts[2],
        });
      }
    }

    return () => {
      dispatch(resetEditorStateAction());
    };
  }, [editor, editor?.state.selection, dispatch, color, roomIdParts, trackUserMetric]);

  const handleSelection = () => {
    const domSelection = window.getSelection();
    const selectedTextDiv = window.getSelection()?.toString() || "";
    if (selectedTextDiv && domSelection) {
      dispatch(
        prevDomSelectionAction({
          domSelection,
          scrollDivLeft: scrollDiv && scrollDiv.scrollLeft,
          scrollDivTop: scrollDiv && scrollDiv.scrollTo,
          proseElement: proseElement,
        })
      );
      dispatch(selectedTextAction(selectedTextDiv));
    }
  };

  // manages comment editor state and panel visibility based on editor and active thread changes
  const activeThread = useActiveThread(editor);
  const commentsOpen = useAppSelector((state) => state.copilotDrawer.commentsOpen);
  useEffect(() => {
    if (!editor) return;

    if (activeThread && !commentsOpen) {
      dispatch(toggleComments(true));
    }

    dispatch(setActiveCommentEditor({ editor, editorId: referenceId }));

    return () => {
      dispatch(setActiveCommentEditor({ editor: undefined, editorId: undefined }));
    };
  }, [editor, activeThread]);

  const [initialThreadId] = useState(searchParams.get("threadId"));
  useEffect(() => {
    if (initialThreadId) {
      dispatch(toggleComments(true));

      if (editor !== null) {
        editor.commands.setActiveComment(initialThreadId);
      }
    }
  }, [initialThreadId, editor]);

  if (!editor) return null;

  return (
    <div className={styles.editorWrapper} ref={editorRef} id="scrollDiv" onPointerUp={handleSelection}>
      {liveCursor && (
        <LiveNameCursors activePage={CopilotPresencePage.Proposal} activeRoomId={roomId} cursorPanel={editorRef} />
      )}
      <div className={classNames(styles.prose, "prose")} id={PROSE_CONTAINER_ID}>
        <div className={styles.container} id="tiptapEditor">
          {editor && <SelectionMenu editor={editor} />}
          {editor && selectedText !== "" && toggleAskAiInputWthDrpDown && (
            <AskAiSearchBar fullscreen={fullscreen} editor={editor} proseElement={proseElement} scrollDiv={scrollDiv} />
          )}
          {editor &&
            toggleAskAiInputWthDrpDown &&
            (editorResponse?.response === undefined || editorResponse.response === "") && (
              <DropDownMenu fullscreen={fullscreen} editor={editor} proseElement={proseElement} scrollDiv={scrollDiv} />
            )}

          {editor &&
            selectedText !== "" &&
            toggleAskAiInputWthDrpDown &&
            (editorResponse?.response === undefined || editorResponse.response === "") && (
              <ToneDropDown fullscreen={fullscreen} editor={editor} proseElement={proseElement} scrollDiv={scrollDiv} />
            )}
          {editor && selectedText !== "" && toggleAskAiInputWthDrpDown && (
            <ResponseDropDown
              fullscreen={fullscreen}
              editor={editor}
              proseElement={proseElement}
              scrollDiv={scrollDiv}
            />
          )}
          <EditorContent editor={editor} className="focus:outline-none" readOnly={toggleAskAiInputWthDrpDown} />
          <CommentDraft
            editor={editor}
            internalContractId={(internalContractId || "") as string}
            referenceId={referenceId as string}
            context={ThreadContext.PROPOSAL}
            editorContainerRef={editorRef}
          />
        </div>
      </div>
    </div>
  );
}

// Prevents a matchesNode error on hot reloading
EditorView.prototype.updateState = function updateState(state) {
  // @ts-ignore
  if (!this.docView) return;
  // @ts-ignore
  // eslint-disable-next-line eqeqeq
  this.updateStateInner(state, this.state.plugins != state.plugins);
};
