/** @jsxImportSource @emotion/react */

import { Dispatch, useEffect, useRef, useState } from "react";
import { Comment } from "../types";
import { CommentEditor } from "./CommentEditor/CommentEditor";
import { useAppSelector } from "store/storeTypes";
import { useCommentOperations } from "../../../api/comments/useCommentOperations";
import Avatar, { VultronAvatar } from "components/molecules/avatar";
import moment from "moment";
import tw from "twin.macro";
import CommentOptions from "./CommentOptions";
import copyText from "utils/copyText";
import { triggerConfirm } from "components/organisms/confirm-modal/utils";

interface CommentViewProps {
  comment: Comment;
  commentIsEditable?: boolean;
  internalContractId: string;
  isLastComment?: boolean;
  threadId?: string;
  referenceId?: string;
  quote_text?: string;
  isActive?: boolean;
  resolved?: boolean;
  setCommentIsEditable?: Dispatch<React.SetStateAction<boolean>>;
  showCommentOptions?: boolean;
}

export const CommentView = ({
  comment,
  internalContractId,
  isLastComment,
  referenceId,
  quote_text,
  threadId,
  isActive,
  commentIsEditable,
  setCommentIsEditable,
  showCommentOptions,
}: CommentViewProps) => {
  const [readonly, setReadonly] = useState(true);
  const formattedDate = moment(comment.updated_at).fromNow(true);
  const currentUser = useAppSelector((store) => store.auth.currentUser);
  const { editCommentMutation, deleteCommentMutation } = useCommentOperations(internalContractId, referenceId);
  const commentRef = useRef<HTMLDivElement>(null);
  const isUserVultron = comment.commenter.is_vultron;

  useEffect(() => {
    // If the comment is the initial active comment, scroll into view
    if (isActive && commentRef.current) {
      setTimeout(() => commentRef.current?.scrollIntoView({ behavior: "smooth", block: "center" }), 150);
    }
  }, [isActive]);

  return (
    <div className="flex w-full flex-col gap-2 group/comment" ref={commentRef}>
      <div className="flex items-center justify-between">
        <div className="flex items-center justify-center gap-2">
          {isUserVultron ? (
            <VultronAvatar size={22} />
          ) : (
            <Avatar size={22} textColor={"#FFFFFF"} name={comment.commenter.username} id={comment.commenter.id} />
          )}
          <div className="flex flex-row items-baseline gap-1.5 ml-[2px]">
            <div className="text-gray-700 font-medium text-sm truncate max-w-[70%]">
              {isUserVultron ? "Vultron" : comment.commenter.username}
            </div>
            <div className="text-gray-light text-xs">{formattedDate}</div>
          </div>
        </div>
        {showCommentOptions && (
          <div
            className="flex flex-row self-start p-1 h-4 items-center justify-center opacity-0 group-hover/comment:opacity-100"
            onFocus={(e) => e.stopPropagation()}
          >
            <CommentOptions
              isCommenter={currentUser?.id === comment.commenter.id}
              onEdit={() => {
                setReadonly(false);
              }}
              onDelete={() => {
                triggerConfirm({
                  header: `Are you sure you want to delete this comment?`,
                  body: "This action is irreversible and will delete this reply from the thread.",
                }).then((proceed) => {
                  if (proceed) {
                    deleteCommentMutation.mutate({ comment_id: comment.id });
                  }
                });
              }}
              onCopyLink={() => {
                if (!threadId) return;
                const url = new URL(window.location.href);
                url.searchParams.set("threadId", threadId);
                url.searchParams.set("commentId", comment.id);
                copyText(url.href);
              }}
            />
          </div>
        )}
      </div>
      <div className="pb-1 pl-5 ml-2.5" css={[!isLastComment && tw`border-l border-l-[#E4E4E4] `]}>
        {quote_text && (
          <div className="flex flex-row pl-2 gap-2 px-1 text-xs border-l-2 border-l-[#E8C969] mb-2">
            <div className="wrap text-gray-light line-clamp-10">{quote_text}</div>
          </div>
        )}
        <CommentEditor
          readonly={readonly && !commentIsEditable}
          content={comment.content}
          showCancel
          onCancel={() => {
            setReadonly(true);
            setCommentIsEditable?.(false);
          }}
          onAddComment={(content) => {
            editCommentMutation.mutate({ content, comment_id: comment.id });
            setReadonly(true);
            setCommentIsEditable?.(false);
          }}
        />
      </div>
    </div>
  );
};
