import { FetchEventSourceInit, fetchEventSource } from "@microsoft/fetch-event-source";
import { useLocalStorage } from "hook/useLocalStorage";
import { useCallback, useRef } from "react";

export const useEnhanceDescription = (options?: FetchEventSourceInit) => {
    const { localValue } = useLocalStorage("vultron_user_token", "");
    const { localValue: workspace_id } = useLocalStorage("vultron_workspace_id", "");
    const { localValue: use_auth0 } = useLocalStorage("vultron_user_use_auth0");
    const useAuth0Header = use_auth0 === true;
    const controllerRef = useRef(new AbortController());
    const { current: controller } = controllerRef;

    const enhanceDescription = (body: { text: string }) => {
        fetchEventSource(`${process.env.REACT_APP_BASE_URL}/contracts/internal/description/enhance/stream`, {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
                Workspace: `Workspace ${workspace_id}`,
                Authorization: `Bearer ${localValue}`,
                "X-Authorization-Auth0": JSON.stringify(useAuth0Header),
                Accept: "application/json",
            },
            body: JSON.stringify(body),
            signal: controller.signal,
            openWhenHidden: true,
            ...options,
        });
    };

    const abortConnection = useCallback(() => {
        controllerRef.current.abort();
        controllerRef.current = new AbortController();
    }, []);

    return { enhanceDescription, abortConnection };
};
