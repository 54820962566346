import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { ComplianceMatrixRow } from "components/copilot/CopilotSchemaImmutableTypes";
import { ResponseTolerance } from "types/Requirement";

export enum QueueSource {
  IdeationDrawer = 1,
  RequirementsTable,
}

interface ResponseQueueRow extends ComplianceMatrixRow {
  response_tolerance: ResponseTolerance;
  hasAtlasResponse: boolean;
  decompose_requirement: boolean;
  red_team: boolean;
}

type State = {
  generateResponseQueue: ResponseQueueRow[];
  autoResponseActive: boolean;
};

const initialState: State = {
  generateResponseQueue: [],
  autoResponseActive: false,
};

export const requirementsSmartResponseReducer = createSlice({
  name: "requirementsSmartResponse",
  initialState,
  reducers: {
    dequeue: (state: State, action: PayloadAction<string>) => {
      const newQueue = state.generateResponseQueue.filter((row) => row.requirement?.id !== action.payload);
      state.generateResponseQueue = newQueue;
      if (!newQueue.length) state.autoResponseActive = false;
    },
    enqueue: (state: State, action: PayloadAction<ResponseQueueRow[]>) => {
      state.generateResponseQueue = [...state.generateResponseQueue, ...action.payload];
      state.autoResponseActive = true;
    },
    clearQueue: (state: State) => {
      state.generateResponseQueue = [];
      state.autoResponseActive = false;
    },
    abortAutoResponse: (state: State) => {
      state.autoResponseActive = false;
    },
  },
});

export const { enqueue, clearQueue, dequeue, abortAutoResponse } = requirementsSmartResponseReducer.actions;

export default requirementsSmartResponseReducer.reducer;
