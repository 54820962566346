import { useEffect, useMemo, useState } from "react";

///////////////// IMPORTING IMAGES AND ICONS //////////////////////
import Icon from "components/atoms/icons/Icon";
import Tooltip from "components/atoms/tooltip/Tooltip";
import { CalenderIcon, CloseIconBlack, MoveIcon } from "utils/icons";
import dots from "../../Assets/3dots.svg";
import dltLoader from "../../Assets/delete-loader.svg";
import doc from "../../Assets/generic-doc.png";
import dropdownIcon from "../../Assets/icon-wrapper.svg";
import { LayoutGrid } from "lucide-react";

/////////////// IMPORTING THIRD PARTY LIBRARIES AND COMPONENTS ////////////
import DatePicker from "react-multi-date-picker";

/////////////////// IMPORTING CUSTOM COMPONENTS AND FUNCTIONS //////////////
import CustomModal from "components/CustomModal";
import ClickAwayListener from "helpers/ClickAwayListener";
import useDocumentPreview from "hook/useDocumentPreview";
import { DropdownMenu } from "components/molecules/dropdown-menu";
import { Download, Trash2 } from "lucide-react";
import tw from "twin.macro";
import { DOCUMENT_TYPES, LEGACY_DOCUMENT_TYPES } from "pages/drive/documents/constants";
//////////////////////// START OF MAIN FUNCTION //////////////////////////
//////////////////////////////////////////////////////////////////////////

export default function Document({
  updateDocument,
  deleteDocument,
  setDraggingFolderId,
  setDraggingDocId,
  docData,
  handleMoveModalOpen,
}) {
  const {
    id,
    name,
    formatted_set_date,
    tags,
    file_extension_type,
    download_url,
    classification,
    updated_at,
    created_at,
    error_message,
  } = docData;
  const [isDocEditModalOpen, setIsDocEditModalOpen] = useState(false);
  const [docName, setDocName] = useState(name);
  const [isClassificationDropdown, setIsClassificationDropdown] = useState(false);
  const [selectedClassification, setSelectedClassification] = useState(DOCUMENT_TYPES[classification] || "");
  const [selectedTags, setSelectedTags] = useState(tags);
  const [updateDate, setUpdateDate] = useState(updated_at);
  const [deleting, setDeleting] = useState(false);
  const { downloadFile } = useDocumentPreview();

  useEffect(() => {
    setSelectedTags(tags);
    setUpdateDate(updated_at);
    setDocName(name.replace(/\.[^/.]+$/, ""));
  }, [tags, updated_at, name]);

  const handleClassificationSelect = (classification) => {
    setSelectedClassification(classification);
    setIsClassificationDropdown(false);
  };

  /////////////// functions updates document data ////////////
  function handleUpdate() {
    const updatedData = {};

    if (docName.trim() !== "") {
      updatedData.name = docName.trim() + "." + (file_extension_type || "");
    }

    updatedData.classification = DOCUMENT_TYPES[selectedClassification]?.toLowerCase()?.split(" ")?.join("_");
    updatedData.tags = selectedTags;
    updatedData.set_date = updateDate;

    setIsDocEditModalOpen(false);
    setDocName("");
    updateDocument(id, updatedData);
  }

  ///////////////////// drag and drop function ////////////////

  const handleDrag = (e) => {
    e.preventDefault();

    setDraggingDocId(id);
    setDraggingFolderId("");
  };

  const menuItems = useMemo(
    () => [
      {
        key: 1,
        label: (
          <div className="flex items-center gap-2">
            <Download size={14} /> Download
          </div>
        ),
        onSelect: () => {
          const a = document.createElement("a");
          a.href = download_url;
          a.download = name;
          a.click();
        },
      },
      {
        key: 2,
        label: (
          <div className="flex items-center gap-2">
            <LayoutGrid size={14} />
            Properties
          </div>
        ),
        onSelect: () => {
          setIsDocEditModalOpen(true);
        },
      },
      {
        key: 3,
        label: (
          <div className="flex items-center gap-2">
            <MoveIcon />
            Move
          </div>
        ),
        onSelect: () => {
          handleMoveModalOpen && handleMoveModalOpen();
        },
      },
      {
        key: 4,
        label: (
          <div className="flex items-center gap-2 text-red-500">
            {deleting ? <img src={dltLoader} alt="icon" width={14} height={14} /> : <Trash2 size={14} />}
            Delete
          </div>
        ),
        disabled: deleting,
        onSelect: () => {
          setDeleting(true);
          deleteDocument(id).finally(() => {
            setDeleting(false);
          });
        },
      },
    ],
    [deleteDocument, deleting, download_url, handleMoveModalOpen, id, name]
  );

  const filteredAndNormalizedDocumentTypes = useMemo(() => {
    return Object.keys(DOCUMENT_TYPES).filter((type) => !LEGACY_DOCUMENT_TYPES.includes(type));
  }, []);

  const normalizeClassificationLabel = (classification) => {
    return classification
      .split("_")
      .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
      .join(" ");
  };

  const fileTooLargeMessages = [
    "Apologies! File is too large to process. For support with larger files, please connect with our support team at support@vultron.ai.",
    "The document size exceeds our processing limits (approximately 500 pages and 200MB). Please contact support@vultron.ai for assistance or to request a limit increase.",
    "The document size exceeds our processing limits (approximately 2000 pages and 500MB). Please contact support@vultron.ai for assistance or to request a limit increase.",
  ];

  const pendingMessage =
    "Vultron is synthesizing and processing the document. This may take up to 45 minutes, depending on the size of the document.";

  return (
    <div className="card flex flex-col 2xl:w-[258px] rounded-lg" onDrag={handleDrag} draggable={!isDocEditModalOpen}>
      <figure
        className="relative overflow-hidden w-full h-48 rounded-md bg-[#DEE1E3] p-5 flex justify-center items-center cursor-pointer"
        onClick={() => downloadFile(download_url)}
      >
        <img src={doc} alt="Doc Preview" loading="lazy" className="w-auto max-w-full pointer-events-none" />
        <div className="iframe-overlay absolute h-full w-full border-[2px] cursor-pointer bg-[transparent]"></div>
      </figure>

      <div className="border-[1px] flex-1 bg-white -mt-12 p-4 rounded-b-md z-[2]">
        <div className="flex justify-between gap-2">
          <p className="font-semibold text-xxs break-all mb-2">{name}</p>
          <DropdownMenu triggerProps={{ css: tw`h-fit` }} items={menuItems}>
            <div className="cursor-pointer ml-auto min-w-[20px]">
              <img src={dots} alt="Three Dots" className="max-w-full mx-auto pointer-events-none object-contain" />
            </div>
          </DropdownMenu>
        </div>
        <div>
          <div>
            <p className="text-xxs text-gray-mid mb-2">{formatted_set_date}</p>
          </div>
          <div className="ml-auto mr-7">
            {error_message ? (
              <div className="flex">
                {fileTooLargeMessages.includes(error_message) ? (
                  <p className="text-xxs text-[#7063FF] mr-1">Limit Exceeded</p>
                ) : (
                  <p className="text-xxs text-[#7063FF] mr-1">Attention Required</p>
                )}
                <Tooltip
                  contentProps={{ alignOffset: -78, align: "start" }}
                  content={
                    <div className="text-gray-darkest">
                      <span className="font-normal">{error_message}</span>
                    </div>
                  }
                  placement="top"
                >
                  <Icon name="InfoCircle" className="w-3 h-3 text-[#7063FF] mt-0.5" />
                </Tooltip>
              </div>
            ) : (new Date() - new Date(created_at)) / 60000 > 45 ? (
              <p className="text-xxs text-[#4cb592]">Processed</p>
            ) : (
              <div className="flex">
                <p className="text-xxs text-[#4680FF] mr-1 ">Pending</p>
                <Tooltip
                  contentProps={{ alignOffset: -78, align: "start" }}
                  content={
                    <div className="text-gray-darkest">
                      <span className="font-normal">{pendingMessage}</span>
                    </div>
                  }
                  placement="top"
                >
                  <Icon name="InfoCircle" className="w-3 h-3 text-[#4680FF] mt-0.5" />
                </Tooltip>
              </div>
            )}
          </div>
        </div>
      </div>

      {/*------------------- MODAL OPENS WHEN CLICK ON PROPERTIES DOC MENU ------------------------*/}
      <CustomModal isOpen={isDocEditModalOpen} onClose={() => setIsDocEditModalOpen(false)}>
        <div className="p-6 w-[663px]">
          <div className="flex justify-between items-center mb-8">
            <h3 className="font-semibold text-sm">Edit Properties</h3>
            <div className="w-[15.56px] h-[15.56px]">
              <span className="cursor-pointer">
                <CloseIconBlack
                  onClick={() => {
                    setIsDocEditModalOpen(false);
                  }}
                  className="h-3 w-3"
                />
              </span>
            </div>
          </div>

          <div className="mb-4">
            <label className="mb-2 block text-sm text-gray-mid">Document Name</label>
            <input
              type="text"
              placeholder="Enter document name"
              className="w-full rounded-md py-2 px-4 h-10 border-[1px] outline-none focus:ring-gray-darkest focus:border-gray-darkest text-sm select-text"
              value={docName}
              onChange={(e) => setDocName(e.target.value)}
            />
          </div>

          <div className="relative mb-4">
            <label className="mb-2 block text-sm text-gray-mid">Document Type</label>
            <ClickAwayListener onClickAway={() => setIsClassificationDropdown(false)}>
              <button
                onClick={() => setIsClassificationDropdown(!isClassificationDropdown)}
                className="relative py-2 w-full px-4 h-10 text-left text-gray-mid bg-white border rounded-md text-sm outline-none focus:ring-gray-darkest focus:border-gray-darkest"
              >
                {normalizeClassificationLabel(selectedClassification) || "Select classification"}

                <img src={dropdownIcon} alt="Icon" loading="lazy" className="absolute h-3 w-3 top-3.5 right-3.5" />
              </button>

              {isClassificationDropdown && (
                <div className="absolute h-40 overflow-y-auto w-full mt-1 bg-white border rounded-lg shadow-[0_9px_28px_#00000014] py-1 z-10">
                  {filteredAndNormalizedDocumentTypes.map((type) => {
                    return (
                      <button
                        key={type}
                        onClick={() => handleClassificationSelect(type)}
                        className="block w-full px-3 py-2 text-left text-sm text-[#1E1E1E] hover:bg-gray-200 focus:outline-none"
                      >
                        {normalizeClassificationLabel(type)}
                      </button>
                    );
                  })}
                </div>
              )}
            </ClickAwayListener>
          </div>
          <div className="mb-3">
            <label className="mb-2 block text-sm text-gray-mid">Date</label>
            <DatePicker
              format="MM/DD/YYYY"
              value={updateDate}
              onChange={(e, t) => {
                setUpdateDate(new Date(t?.validatedValue[0]).toISOString());
              }}
              containerClassName="!w-full !max-w-full"
              render={(value, openCalendar) => {
                return (
                  <div
                    onClick={openCalendar}
                    tabIndex="0"
                    className="relative text-gray-mid py-2 cursor-pointer select-none w-full px-4 overflow-hidden border text-sm rounded-md focus:ring-gray-darkest focus:border-gray-darkest block"
                  >
                    <div className="absolute inset-y-0 right-3 flex items-center cursor-pointer select-none">
                      <CalenderIcon className="w-3" />
                    </div>
                    <p>{updateDate.split("T")[0] || "Update Date"}</p>
                  </div>
                );
              }}
            />
          </div>

          <div className="flex justify-end gap-4 mt-14">
            <button
              type="button"
              className="border-[1px] border-[#DBE0E5] rounded-lg py-[9px] px-4 text-sm font-medium text-[#1D2630]"
              onClick={() => setIsDocEditModalOpen(false)}
            >
              Cancel
            </button>
            <button
              type="submit"
              className="border-0 bg-gray-darkest rounded-lg py-[9px] px-4 text-sm font-medium text-[#ffffff] flex items-center gap-2"
              onClick={handleUpdate}
            >
              Save
            </button>
          </div>
        </div>
      </CustomModal>
    </div>
  );
}
