/** @jsxImportSource @emotion/react */

import Tooltip from "components/atoms/tooltip/Tooltip";
import { createPortal } from "react-dom";
import { CircleMinus, CirclePlus, Trash2 } from "lucide-react";
import { forwardRef, useEffect, useLayoutEffect, useMemo, useRef, useState } from "react";
import { DraggableProvided, DraggableStateSnapshot } from "react-beautiful-dnd";
import { Button } from "components/editor/components";
import tw from "twin.macro";
import Icon from "components/atoms/icons/Icon";
import SourcePopover from "components/molecules/source-popover";
import { SourceType, Storage } from "components/copilot/CopilotSchemaTypes";
import { ComplianceMatrixRowContentBody } from "components/copilot/CopilotSchemaImmutableTypes";
import { useMutation } from "YJSProvider/createYJSContext";
import { find, LiveList } from "YJSProvider/LiveObjects";

type IdeationContentCardProps = {
  item: ComplianceMatrixRowContentBody;
  type: "generated" | "selected";
  provided: DraggableProvided;
  snapshot: DraggableStateSnapshot;
  itemId?: string;
  index: number;
  containerRef: HTMLDivElement | null;
};

const IdeationContentCard = forwardRef<HTMLDivElement, IdeationContentCardProps>(
  ({ item, provided, snapshot, type, itemId, index, containerRef }, ref) => {
    const isGenerated = type === "generated";
    const { source_type, source_name, source_extension_type, reference_id, source_content } = item;
    const contentRef = useRef<HTMLDivElement>(null);
    const [showMore, setShowMore] = useState({ visible: false, open: false });
    const [sourcePopoverOpen, setSourcePopoverOpen] = useState(false);

    const mapSourceType = useMemo(() => {
      if (source_type === SourceType.Custom) {
        return "Custom Response";
      } else if (source_type === SourceType.AI) {
        return "Vultron AI";
      } else if (source_type === SourceType.ContentDrive) {
        return "Content Library";
      }
      return "";
    }, [source_type]);

    let portal = useRef<HTMLDivElement | null>(null);
    useEffect(() => {
      portal.current = document.createElement("div");
      document.body.appendChild(portal.current);
      return () => {
        if (portal.current) document.body.removeChild(portal.current);
      };
    }, []);

    useLayoutEffect(() => {
      if (contentRef.current) {
        if (contentRef.current.scrollHeight > contentRef.current.clientHeight) {
          setShowMore({ visible: true, open: false });
        } else {
          setShowMore({ visible: false, open: false });
        }
      }
    }, [itemId]);

    const deleteGeneratedContent = useMutation(
      ({ storage }, idx) => {
        const matrix = storage.get("compliance_matrix") as Storage["compliance_matrix"] | undefined;
        if (!matrix) return;
        const liveRequirement = find(matrix, (row) => row.get("requirement")?.get("id") === itemId);
        liveRequirement?.get("generated_content_ideas_v2")?.delete(idx);
      },
      [itemId]
    );

    const selectGeneratedContent = useMutation(
      ({ storage }, idx) => {
        const matrix = storage.get("compliance_matrix") as Storage["compliance_matrix"] | undefined;
        if (!matrix) return;
        const liveRequirement = find(matrix, (row) => row.get("requirement")?.get("id") === itemId);
        const activeContent = liveRequirement?.get("generated_content_ideas_v2")?.get(idx);
        const selectedContent = liveRequirement?.get("selected_content_v2");

        if (!activeContent) return;
        liveRequirement?.get("generated_content_ideas_v2")?.delete(index);
        if (!selectedContent) {
          liveRequirement?.set("selected_content_v2", new LiveList([activeContent]));
        } else selectedContent?.push([activeContent]);
      },
      [itemId]
    );

    const deselectContent = useMutation(
      ({ storage }, idx) => {
        const matrix = storage.get("compliance_matrix") as Storage["compliance_matrix"] | undefined;
        if (!matrix) return;
        const liveRequirement = find(matrix, (row) => row.get("requirement")?.get("id") === itemId);
        const activeContent = liveRequirement?.get("selected_content_v2")?.get(idx);
        const generatedContent = liveRequirement?.get("generated_content_ideas_v2");

        if (!activeContent) return;
        liveRequirement?.get("selected_content_v2")?.delete(index);
        if (!generatedContent) {
          liveRequirement?.set("selected_content_v2", new LiveList([activeContent]));
        } else generatedContent?.push([activeContent]);
      },
      [itemId]
    );

    const usePortal = snapshot.isDragging;

    const child = (
      <div
        {...provided.dragHandleProps}
        {...provided.draggableProps}
        style={provided.draggableProps.style}
        ref={provided.innerRef}
        className="p-3 bg-[#F8F9FA] rounded-md border border-[#DBE0E5] mb-2.5 active:cursor-grabbing"
      >
        <div className="text-xs mb-4">
          <div ref={contentRef} css={[!showMore.open && tw`line-clamp-4`]}>
            {item?.content}
          </div>
          {showMore.visible && (
            <>
              {!showMore.open && (
                <Button
                  variant="link"
                  onClick={() =>
                    setShowMore((prev) => ({
                      ...prev,
                      open: true,
                    }))
                  }
                  className="font-semibold mt-0.5"
                >
                  Show more
                </Button>
              )}
              {showMore.open && (
                <Button
                  variant="link"
                  onClick={() =>
                    setShowMore((prev) => ({
                      ...prev,
                      open: false,
                    }))
                  }
                  className="font-semibold mt-0.5"
                >
                  Show less
                </Button>
              )}
            </>
          )}
        </div>
        <div className="flex justify-between items-center flex-wrap gap-2">
          <div className="flex items-center">
            <p className="text-xs">
              <span className="text-[#2A47AB] font-medium">{mapSourceType}</span>
            </p>
            <div className="flex items-center">
              {!!reference_id && (
                <>
                  <div className="w-px h-4 bg-gray-400 ml-2 mr-1" />
                  <SourcePopover
                    modal
                    open={sourcePopoverOpen}
                    onOpenChange={setSourcePopoverOpen}
                    source={{
                      id: reference_id,
                      sourceContent: source_content,
                      extension: source_extension_type,
                      name: source_name,
                    }}
                    portalProps={{ container: containerRef }}
                    contentProps={{ align: "start", alignOffset: -8, autoFocus: false }}
                  >
                    <div
                      className="text-gray-mid font-medium text-xs flex gap-1 items-center cursor-pointer duration-150 hover:bg-gray-200 hover:text-slate-600 p-1 rounded-md"
                      css={[sourcePopoverOpen && tw`pointer-events-none text-slate-600 bg-gray-200`]}
                    >
                      Source <Icon name="NoteFold" className="w-3" />
                    </div>
                  </SourcePopover>
                </>
              )}
            </div>
          </div>
          <div className="flex gap-1 text-slate-500">
            {isGenerated && (
              <button
                type="button"
                className="outline-none border-none flex justify-center items-center"
                onClick={() => deleteGeneratedContent(index)}
              >
                <Trash2 className="duration-150 hover:text-slate-700" />
              </button>
            )}
            {!isGenerated && (
              <Tooltip content="Deselect">
                <button
                  type="button"
                  className="outline-none border-none flex justify-center items-center"
                  onClick={() => deselectContent(index)}
                >
                  <CircleMinus size={20} className="duration-150 hover:text-slate-600" />
                </button>
              </Tooltip>
            )}
            {isGenerated && (
              <Tooltip content="Select">
                <button
                  type="button"
                  className="outline-none border-none flex justify-center items-center"
                  onClick={() => selectGeneratedContent(index)}
                >
                  <CirclePlus size={20} className="duration-150 hover:text-slate-600" />
                </button>
              </Tooltip>
            )}
          </div>
        </div>
      </div>
    );

    if (usePortal && portal.current) {
      return createPortal(child, portal.current);
    }

    return child;
  }
);

export default IdeationContentCard;
