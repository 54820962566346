/** @jsxImportSource @emotion/react */

import { memo, useEffect, useMemo, useState } from "react";
import AIAssistantInput from "./ai-assistant-input/AIAssistantInput";
import AIAssistantConversation from "./AIAssistantConversation";
import { useStorage } from "utils/yjs-configs/ai-assistant/yjs.config";
import Tooltip from "components/atoms/tooltip";
import tw from "twin.macro";
import "./styles.css";
import { useAppDispatch } from "store/storeTypes";
import { useParams } from "react-router-dom";
import { resetAssistantState, setAiAssistantState } from "store/reducers/ai-assistant/aiAssistantReducer";
import AIAssistantSidebar from "./ai-assistant-sidebar";
import { fetchFileStorage } from "store/reducers/driveReducerSlice";

const AIAssistantContainer = () => {
  const { currentSessionId } = useParams();
  const dispatch = useAppDispatch();

  const aiAssistantSessions = useStorage((root) => root.ai_assistant.sessions);

  const activeSession = useMemo(
    () => aiAssistantSessions.find((session) => session.id === currentSessionId),
    [aiAssistantSessions, currentSessionId]
  );

  useEffect(() => {
    dispatch(setAiAssistantState({ activeSession }));
  }, [activeSession, dispatch]);

  useEffect(() => {
    dispatch(fetchFileStorage());
    return () => {
      dispatch(resetAssistantState());
    };
  }, [dispatch]);

  return <AIAssistantCore />;
};

export default AIAssistantContainer;

const AIAssistantCore = memo(() => {
  const [sidebarOpen, setSidebarOpen] = useState(true);
  const [isInitial, setIsInitial] = useState(true);

  return (
    <div className="bg-white relative z-0 flex h-full w-full overflow-hidden">
      <AIAssistantSidebar isInitial={isInitial} sidebarOpen={sidebarOpen} />
      <div className="relative flex flex-col flex-1">
        <div className="flex flex-col h-full">
          <div className="flex-1 overflow-hidden">
            <div className="relative h-full">
              <AIAssistantConversation />
            </div>
          </div>
          <AIAssistantInput />
        </div>
        <Tooltip
          contentProps={{ side: "right", align: "center" }}
          content={!sidebarOpen ? "Open sidebar" : "Close sidebar"}
        >
          <button
            onClick={() => {
              setSidebarOpen((prev) => !prev);
              if (isInitial) setIsInitial(false);
            }}
            className="group absolute left-0 top-[calc(50%-72px)] flex h-[72px] w-8 items-center justify-center"
          >
            <div className="flex h-6 w-6 flex-col items-center">
              <div
                className="h-3 w-1 rounded-full duration-200 bg-slate-400 translate-y-0.5 group-hover:bg-slate-900"
                css={[sidebarOpen && tw`group-hover:rotate-[15deg]`, !sidebarOpen && tw`rotate-[-15deg]`]}
              />
              <div
                className="h-3 w-1 rounded-full duration-200 bg-slate-400 -translate-y-0.5 group-hover:bg-slate-900"
                css={[sidebarOpen && tw`group-hover:rotate-[-15deg]`, !sidebarOpen && tw`rotate-[15deg]`]}
              />
            </div>
          </button>
        </Tooltip>
      </div>
    </div>
  );
});
