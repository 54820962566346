import moment from "moment";
import { BaseBlock } from "../CopilotSchemaImmutableTypes";
import { getFileIcon } from "utils/getFileIcon";
import { useAppDispatch } from "store/storeTypes";
import { toggleSourceModal } from "store/reducers/modalsSlice";
import Icon from "components/atoms/icons/Icon";

const SourceWrapper = ({ sources }: { sources: BaseBlock["sources"] }) => {
    const dispatch = useAppDispatch();

    return (
        <div className="flex items-center gap-2 py-0.5 overflow-x-auto">
            {sources.map((source) =>
                source.extension_type === "internet_search" ? (
                    <button
                        key={source.reference_id}
                        onClick={() => {
                            window.open(source.reference_id, "_blank");
                        }}
                        className="flex gap-2 items-center rounded border-[1.5px] border-gray-mid pl-2 py-1.5 pr-3 max-w-[220px] duration-100 hover:bg-gray-100"
                    >
                        <Icon name="Internet" className="h-[24px] w-[24px] shrink-0 text-slate-600" />
                        <div className="flex flex-col items-start min-w-0">
                            <div className="text-xs text-gray-darkest max-w-full truncate">
                                {source.name ? source.name : source.reference_id}
                            </div>
                            {source.name && (
                                <div className="flex items-center max-w-full">
                                    <span className="text-gray-mid text-xs">{source.citation_reference}</span>
                                    <div className="text-xxs text-gray-mid truncate ml-1.5">{source.reference_id}</div>
                                </div>
                            )}
                        </div>
                    </button>
                ) : (
                    <button
                        key={source.reference_id}
                        onClick={() => {
                            dispatch(
                                toggleSourceModal({
                                    open: true,
                                    initialProps: {
                                        id: source.reference_id,
                                        sourceContent: source.content,
                                        extension: source.extension_type,
                                        name: source.name,
                                    },
                                })
                            );
                        }}
                        className="flex gap-2 min-w-fit items-center rounded border-[1.5px] border-gray-mid pl-2 py-1.5 pr-3 max-w-[220px] duration-100 hover:bg-gray-100"
                    >
                        <img src={getFileIcon(source.extension_type)} alt="" className="h-[24px]" />
                        <div className="flex flex-col items-start min-w-0">
                            <div className="text-xs text-gray-darkest max-w-full truncate">{source.name}</div>
                            <div className="text-xxs text-gray-mid whitespace-nowrap">
                                {moment(source?.date).format("MMMM DD, YYYY")}
                            </div>
                        </div>
                    </button>
                )
            )}
        </div>
    );
};

export default SourceWrapper;
