import { useState } from "react";
import { downloadProposal } from "api/api";
import { Spinner } from "utils/icons";
import { useSelector } from "react-redux";
import { EditorToggler } from "../EditorToggler";
import { CopilotPresencePage } from "types/Presence";
import { useSearchParams } from "react-router-dom";
import Icon from "components/atoms/icons/Icon";
import { Upload } from "lucide-react";
import { Avatars } from "components/editor/components/Avatars";

const Proposal = ({ fullscreen, setForceRefresh, setDocId, proposalDocId }) => {
  const [searchParams] = useSearchParams();
  const nestedTab = searchParams.get("tab")?.toLocaleLowerCase();
  const fullScreen = useSelector((state) => state.aiReducer.isExpanded);
  const [exporting, setExporting] = useState(false);
  return (
    <div className="pt-3 px-5 flex flex-col flex-1 overflow-x-hidden overflow-y-auto">
      <div className="flex items-center pb-2 mb-1">
        <div className="flex items-center justify-between w-full gap-2 ml-auto">
          <button
            onClick={() => {
              setDocId("");
              setForceRefresh(true);
            }}
            className="h-8 px-2.5 flex items-center justify-center rounded-lg border border-[rgb(218,220,224)] text-[rgb(95,99,104)] text-sm hover:bg-[rgb(218,220,224,0.2)] active:text-[rgb(60,64,67)]"
          >
            <Icon name="ArrowLeft" className="h-4 w-4" />
            All Proposals
          </button>
          <div className="flex items-center gap-1">
            <Avatars />
            <button
              className="h-8 px-2.5 flex items-center justify-center rounded-lg border border-[rgb(218,220,224)] text-[rgb(95,99,104)] hover:bg-[rgb(218,220,224,0.2)] active:text-[rgb(60,64,67)]"
              onClick={() => {
                setExporting(true);
                const dataFormat = "yjs";
                downloadProposal(proposalDocId, dataFormat)
                  .then((response) => {
                    console.log({ Resdata: response.data });
                    const url = window.URL.createObjectURL(new Blob([response.data]));
                    console.log({ url });
                    const link = document.createElement("a");
                    const filename = `proposal_${new Date().toISOString().split("T")[0]}.docx`;
                    link.href = url;
                    link.setAttribute("download", filename);
                    document.body.appendChild(link);
                    link.click();
                  })
                  .catch((error) => console.error(error))
                  .finally(() => setExporting(false));
              }}
            >
              {exporting ? <Spinner classes="!text-black items-center" width={13} height={13} /> : <Upload size={14} />}
            </button>
          </div>
        </div>
      </div>
      <div
        className="border bg-white px-3 pb-2 overflow-hidden flex flex-col flex-1"
        style={{
          position: fullScreen ? "fixed" : "",
          top: fullScreen ? "64px" : "",
          left: fullScreen ? "233px" : "",
          width: fullScreen ? "calc(100% - 233px)" : "",
          bottom: 0,
          overflow: "hidden",
          zIndex: fullScreen ? "20" : "",
        }}
      >
        <EditorToggler fullscreen={fullscreen} liveCursor={CopilotPresencePage.Proposal === nestedTab} />
      </div>
    </div>
  );
};

export default Proposal;
