import axios from "axios";
import { UserMeta, Storage } from "./schema";
import { createYJSContext } from "YJSProvider/createYJSContext";

const authenticateAIAssistant = async () => {
    return await axios
        .post("/chat/ai_assistant/token")
        .then((response) => {
            if (response.status === 401) {
                // @ts-ignore
                window.location = "/auth";
                return Promise.reject();
            }
            return response.data.token;
        })
        .catch((e) => {
            if (e.response.status === 401) {
                // @ts-ignore
                window.location = "/auth";
            }
        });
};

export const { RoomProvider, useMutation, useStorage, useStatus, useRoom } = createYJSContext<
    UserMeta,
    Storage,
    typeof authenticateAIAssistant
>(authenticateAIAssistant);
