import { useComments } from "../../../api/comments/useComments";
import { Thread } from "./Thread";
import { useActiveThread } from "./useThread";
import Loader from "utils/Spinner";
import { DropdownMenu } from "components/molecules/dropdown-menu";
import { ListFilter } from "lucide-react";
import { CommentsFilterState, ThreadContext } from "../types";
import { useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";
import { useProxyRef } from "hook/useProxyRef";
import { useAppSelector } from "store/storeTypes";
import { useThreadEditorMapping } from "./useThreadEditorMapping";
import { Editor } from "@tiptap/react";

interface CommentsPanelProps {
  context: ThreadContext;

  internalContractId: string;
  referenceId?: string;
  filterState?: CommentsFilterState;
}
export const CommentsPanel = ({ internalContractId, referenceId, context }: CommentsPanelProps) => {
  const [searchParams, setSearchParams] = useSearchParams();
  const commentIdParam = searchParams.get("commentId");
  const [filterState, setFilterState] = useState<CommentsFilterState>(CommentsFilterState.Open);
  const currentFilterState = useProxyRef(filterState);
  const { resolvedThreads, unresolvedThreads, isLoading, data } = useComments(internalContractId, referenceId);
  const editor = useAppSelector((state) => state.copilot.activeCommentEditor);
  const activeEditorID = useAppSelector((state) => state.copilot.activeEditorId);
  const activeThreadId = useActiveThread(editor);
  const [activeCommentId, setActiveCommentId] = useState<string | null>(null);
  const threadEditorMapping = useThreadEditorMapping(data, context, internalContractId, referenceId);

  useEffect(() => {
    // update filter state when active thread changes
    if (filterState === CommentsFilterState.Resolved && activeThreadId) {
      setFilterState(CommentsFilterState.Open);
    }
  }, [activeThreadId, filterState]);

  const handleFilterChange = (newState: CommentsFilterState) => {
    setFilterState(newState);
    if (newState === CommentsFilterState.Resolved) {
      editor?.commands.setActiveComment(undefined);
    }
  };

  useEffect(() => {
    setActiveCommentId(commentIdParam);
    if (commentIdParam) {
      setSearchParams(
        (prev) => {
          prev.delete("commentId");
          return prev;
        },
        { replace: true }
      );
    }
  }, [commentIdParam, setSearchParams]);

  useEffect(() => {
    if (!activeThreadId || isLoading) return;
    // check that the current filter state matches the thread resolved state
    const thread = unresolvedThreads.find((thread) => thread.id === activeThreadId);
    if (!thread && currentFilterState.current === CommentsFilterState.Open) {
      setFilterState(CommentsFilterState.Resolved);
    }
  }, [activeThreadId, isLoading]);

  if (isLoading)
    return (
      <div className="flex flex-1 items-center justify-center">
        <Loader type={undefined} />
      </div>
    );

  // determine which threads to display based on filter state
  const threads = filterState === CommentsFilterState.Resolved ? resolvedThreads : unresolvedThreads;

  return (
    <div className="flex flex-col flex-1 overflow-auto w-full">
      <div className="flex flex-row justify-between items-center px-4 py-1 border-y border-b-slate-200">
        <span className="text-sm">{filterState} Comments</span>
        <DropdownMenu
          items={[
            {
              key: CommentsFilterState.Open,
              label: CommentsFilterState.Open,
              onSelect: () => handleFilterChange(CommentsFilterState.Open),
              selected: filterState === CommentsFilterState.Open,
            },
            {
              key: CommentsFilterState.Resolved,
              label: CommentsFilterState.Resolved,
              onSelect: () => handleFilterChange(CommentsFilterState.Resolved),
              selected: filterState === CommentsFilterState.Resolved,
            },
          ]}
          triggerProps={{ className: "p-1 rounded-md hover:bg-hover-default" }}
        >
          <ListFilter size={22} color={"#7A7F84"} />
        </DropdownMenu>
      </div>
      {threads.length === 0 && (
        <div className="pt-2 flex flex-col items-center h-full justify-center">
          <span className="font-medium text-xl mb-1">No comments</span>
        </div>
      )}
      <div className="flex-1 overflow-auto w-full">
        {threads.map((thread) => {
          let threadEditor: Editor | undefined | null;
          if (context === ThreadContext.PROPOSAL) {
            threadEditor = editor;
          } else {
            threadEditor = threadEditorMapping[thread.id] === activeEditorID ? editor : undefined;
          }
          return (
            <Thread
              editorId={threadEditorMapping[thread.id]}
              editor={threadEditor}
              thread={thread}
              key={thread.id}
              internalContractId={internalContractId}
              referenceId={referenceId}
              isActiveThread={thread.id === activeThreadId}
              context={context}
              activeCommentId={activeCommentId}
              filterState={filterState}
            />
          );
        })}
      </div>
    </div>
  );
};
