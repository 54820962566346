import { authenticateToProposal } from "YJSProvider/client";
import { createYJSContext } from "YJSProvider/createYJSContext";
import { LiveObject, ToImmutable } from "YJSProvider/LiveObjects";

export type ProposalDocStorage = LiveObject<{}>;

interface BaseMeta {
  presence: {};
  info: {
    id: string;
  };
}

export const {
  RoomProvider,
  useRoom,
  useUpdateMyPresence,
  useSelf,
  useOthers,
  useOthersMapped,
  useStorage,
  useMutation,
  useStatus,
} = createYJSContext<BaseMeta, ToImmutable<ProposalDocStorage>, typeof authenticateToProposal>(authenticateToProposal);
